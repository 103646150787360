<template>
    <houses-module-component/>
</template>

<script>
    import HousesModuleComponent from "@/components/admin/modules/HousesModuleComponent";
    
    export default {
        name: "HousesModule",
        title: "Gestión de Casas | Turismo BC",
        components: { HousesModuleComponent },
    }
</script>

<style scoped>

</style>